import React, { lazy } from "react";
import { graphql } from "gatsby";
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
  Button,
} from '../ui-components';
import {
  Wrapper,
  Container,
  DescriptionMain,
} from '../components/mission-statement/mission-statement.styled';
import {
  ItemGrid,
  CardThumbnails,
} from "../ui-components/scss"

import { countries } from "../utils";


const RentalsPage = ({ data, location }) => {
  const content = data.content.frontmatter || {};
  const html = data.content.html;
  const rentalList = data.rentalList.nodes[0].data || [];

  const rentalCountries = rentalList.map(data => {
    const country = countries.find(el => el.code === data.region);
    const rentals = rentalList.map(el => ({ ...el, country: country.name })).filter(el => el.region == data.region).sort((a, b) => a.order - b.order);
    return (
      {
        region: data.region,
        country: country.name,
        rentals: rentals,
      })
  }).sort((a, b) => a.name - b.name)

  return (
    <>
      <SEO
        canonical="/rentals/"
        title={content.title}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>

          <PageTitle>{content.title}</PageTitle>
          <DescriptionMain dangerouslySetInnerHTML={{ __html: content.description }} />

          <br />

          {rentalCountries.map((data, index) => (
            <ItemGrid
              key={index}
              item="thumbnail"
              title={data.country}
            >
              {data.rentals.map((data, index) =>
                <CardThumbnails
                  key={index}
                  title={data.title}
                  description={data.details}
                  image={data.image?.original || null}
                  url={data.website}
                  call2action="Rent"
                />
              )}
            </ItemGrid>
          ))}

        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>
    </>
  );
}
export default RentalsPage;


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    content: markdownRemark(fileAbsolutePath: {regex: "/content/pages/rentals.md/"}) {
      frontmatter {
        title
      }
      html
    }
    rentalList: allRestApiV1UserCommunityRoleRentalList {
      nodes{ 
        data{
        address {
          address
          address_2
          city
          postal_code
          country
          state
        }
        email
        title
        region
        website
        image {
          original
        }
        details
      }
    }
    }
  }
  `;

